import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import '../components/index-page.sass'
import BlogRoll from '../components/BlogRoll'
import Layout from '../components/Layout'
import ProjectRoll from '../components/ProjectRoll'
import Cover from '../../static/img/cover.svg'
import { Bottom, Top } from '../components/Cover'
import useSiteMetadata from '../components/SiteMetadata'

export const IndexPageTemplate = ({
  title,
  heading,
  subheading,
  mainpitch,
  description,
  body,
}) => {
  const [subText, setSubText] = useState({
    show: false,
    text: '',
    nextText: subheading[0],
  })
  let intervalNum

  useEffect(() => {
    let position = 0
    intervalNum = setInterval(() => {
      const text = subheading[position]
      position = position === subheading.length - 1 ? 0 : position + 1
      setSubText({ text, show: false, nextText: subheading[position] })
    }, 3500)
    return () => clearInterval(intervalNum)
  }, [])

  useEffect(() => {
    if (!subText.show) {
      setTimeout(() => {
        setSubText({ ...subText, text: subText.nextText, show: true })
      }, 500)
    }
  }, [subText])

  return (
    <div>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          backgroundImage: `url(${Cover})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 50%',
          position: 'relative',
        }}
      >
        <div className={'svg-container svg-top'}>
          <Top />
        </div>
        <div
          style={{
            display: 'flex',
            height: '20vh',
            lineHeight: '1',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: 'column',
            zIndex: 10,
          }}
        >
          <h1
            className={`is-size-4-mobile is-size-3-tablet is-size-2-widescreen animated-text`}
            style={{
              padding: '0.25em',
              textAlign: 'center',
              opacity: subText.show ? 1.0 : 0.0,
            }}
          >
            {subText.text}
          </h1>
          <h3
            className="is-size-6-mobile is-size-5-tablet is-size-4-widescreen subtext"
            style={{
              textAlign: 'center',
              padding: '0.25em',
            }}
          >
            {title}
          </h3>
        </div>
        <div className={'svg-container svg-bottom'}>
          <Bottom />
        </div>
      </div>

      {/*</div>*/}
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="content">
                    <div className="tile">
                      <h1 className="title">{mainpitch}</h1>
                    </div>
                    <div className="tile">
                      <h4
                        className="subtitle"
                        dangerouslySetInnerHTML={{ __html: body }}
                      />
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-12">
                      <h2>{heading}</h2>
                      <p>{description}</p>
                    </div>
                  </div>
                  <ProjectRoll />
                  <div className="column is-12">
                    <h2>From My Blog</h2>
                    <BlogRoll />
                    {/*<div className="column is-12 has-text-centered">*/}
                    {/*  <Link className="btn" to="/blog">*/}
                    {/*    Read more*/}
                    {/*  </Link>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.array,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  body: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  const { title, description } = useSiteMetadata()
  return (
    <Layout title={title} description={description}>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        body={html}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.string,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        heading
        subheading
        mainpitch
        description
      }
    }
  }
`
