import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import ProjectPreview from './ProjectPreview'
import './projectRoll.sass'

const ProjectRoll = ({ data, count }) => {
  const [postIndex, _setPostIndex] = useState(0)
  const { edges: posts } = data.allMarkdownRemark
  const slicedPosts = posts.slice(postIndex, postIndex + 10)
  const orderedPosts = []
  const rows = { rectangle: [], square: [] }
  for (const { node: post } of slicedPosts) {
    const {
      frontmatter: { type },
    } = post
    rows[type].push(post)
    if (rows[type].length === 2) {
      orderedPosts.push(...rows[type])
      rows[type] = []
    }
  }
  if (rows.rectangle.length) {
    orderedPosts.push(...rows.rectangle)
  } else if (rows.square.length) {
    orderedPosts.push(...rows.square)
  }

  return (
    <div className="columns is-multiline">
      {orderedPosts &&
        orderedPosts.map(post => <ProjectPreview post={post} key={post.id} />)}
    </div>
  )
}

ProjectRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  count: PropTypes.number,
}

export default () => (
  <StaticQuery
    query={graphql`
      query ProjectRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "project-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                description
                overlay
                source
                demo
                featuredpost
                type
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 640, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ProjectRoll data={data} count={count} />}
  />
)
