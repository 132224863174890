import PreviewCompatibleImage from './PreviewCompatibleImage'
import { Link } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'

const ProjectPreview = ({ post }) => {
  const {
    fields: { slug },
    title,
    frontmatter: { description, featuredimage, overlay },
  } = post

  // console.log({post})

  return (
    <div className="is-parent column is-6 project-preview">
      <Link to={slug}>
        <div className={`image-container overlay-${overlay}`}>
          <h2 className="image-title">{title ? title.toUpperCase() : ''}</h2>
          <h3 className="image-description">{description}</h3>
        </div>
      </Link>
      <Link to={slug}>
        <PreviewCompatibleImage
          imageInfo={{
            image: featuredimage,
            alt: `featured image thumbnail for post ${title}`,
          }}
          zoom={true}
        />
      </Link>
    </div>
  )
}

ProjectPreview.propTypes = {
  post: PropTypes.shape({
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
    title: PropTypes.string.isRequired,
    frontmatter: PropTypes.shape({
      description: PropTypes.string.isRequired,
      featuredimage: PropTypes.any.isRequired,
      overlay: PropTypes.oneOf(['brown', 'indigo', 'green', 'blue-gray']),
    }),
  }),
}

export default ProjectPreview
